import * as React from "react";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { SEO } from "../components/SEO";
import Button from "../components/ui/Button";

// markup
function NotFoundPage() {
  return (
    <>
      <Header />
      <Content light>
        <div className="bg-white">
          <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-base font-semibold uppercase tracking-wide text-primary-600">
                Uh Oh, this page
              </h2>
              <p className="mt-1 font-serif text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Hasn&apos;t been built yet
              </p>
              <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
                We can&apos;t find this page, but we can help you build your
                dream home.
              </p>
              <Button to="/" size="lg" className="mt-6">
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
}

export default NotFoundPage;

export const Head = () => <SEO title="Page Not Found" />;
